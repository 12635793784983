// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

$setter-blue:           #344E63;
$setter-blue-light:     #6A8392;
$setter-blue-lighter:   #A1B8C1;
$setter-blue-dark:      #24384D;
$setter-blue-darker:    #152238;

$setter-red:           #FF4856;
$setter-red-light:     #FF6D77;
$setter-red-lighter:   #FF9297;
$setter-red-dark:      #CA323C;
$setter-red-darker:    #941D22;

.small-helper-link {
  background: none!important;
  border: none;
  padding: 0!important;
  color: $setter-red;
  font-style: italic;
  font-weight: 200;
  text-decoration: underline;
  font-size: 14px;
}

#structured-address-container .form-control.is-invalid+.icon.icon-sm {
  display: none;
}

.form-group.with-reset-form-link {
  margin-bottom: 0.25rem;
}
